.contact-form-v1 {
	padding-bottom: 62px !important;
	border: 1px solid #d3d3d3;

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}
}