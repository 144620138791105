.faq-v2 {
	margin-top: var(--page-margin-top);

	.card {
		background-color: transparent;
		border: 1px solid var(--color-grey-normal);

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0px 5px;
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);
		position: relative;

		&:after {
			content: "\f107";
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			position: absolute;
			right: 9px;
			top: 7px;
		}

		&[aria-expanded="true"] {
			&:after {
				transform: rotate(180deg);
			}

		}

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

}