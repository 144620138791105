.static-view {
	.inner-banner {
		&.faq-banner {
			background-image: url(RESOURCE/img/Headerbild_FAQ.jpg);
		}

		&.about-banner {
			background-image: url(RESOURCE/img/Headerbild_Ueberuns.jpg);
		}

		&.contact-banner {
			background-image: url(RESOURCE/img/Headerbild_Kontakt.jpg);
		}
	}
}